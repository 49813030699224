<template>
  <v-row class="d-flex justify-center mt-0">
    <template v-if="$store.state.banners.length > 0">
      <v-carousel
        v-model="model"
        prev-icon="fas fa-angle-left"
        next-icon="fas fa-angle-right"
        :height="$vuetify.breakpoint.smAndDown ? '80vh' : '90vh'"
        :show-arrows="arrow"
        :interval="12000"
        hide-delimiters
        cycle
      >
        <div @click="$vuetify.breakpoint.smAndDown ? arrow = !arrow : ''">
          <v-carousel-item
            v-for="(item, index) in $store.state.banners"
            :key="index"
            :src="`data:image/jpeg;base64,${item.img}`"
            contain
          ></v-carousel-item>
        </div>
      </v-carousel>
      <div class="my-2">
        <v-btn
          v-for="i in $store.state.banners.length"
          :key="i-1"
          icon
          @click="model = i-1"
        >
          <v-icon small>
            {{ i-1 === model ? 'fas' : 'far' }} fa-circle
          </v-icon>
        </v-btn>
      </div>
    </template>
    <template v-else>
      <v-col cols="12" class="mb-4">
        <div class="pa-2 text-center" style="font-size: 28px">
        {{ saludo }} {{ $store.state.username }}
      </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-img
          :src="require('../assets/logo.webp')"
          height="100"
          contain
        ></v-img>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      model: 0,
      arrow: true,
      saludo: ''
    }
  },
  created () {
    const hora = moment().hour()
    if (hora >= 0 && hora < 12) {
      this.saludo = 'Buenos días'
    }
    else if (hora >= 12 && hora < 20) {
      this.saludo = 'Buenas tardes'
    }
    else {
      this.saludo = 'Buenas noches'
    }
  }
}
</script>